import { useCallback, useState } from 'react';
import classNames from 'classnames';

import AppLottie from '../AppLottie';
import AppLink from '../AppLink';

import styles from './AppSlider.module.scss';

export default function AppSlider({ slider }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? slider?.data.length - 1 : prevIndex - 1));
  }, [slider?.data.length]);

  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex === slider?.data.length - 1 ? 0 : prevIndex + 1));
  }, [slider?.data.length]);


  return (
    <div className={styles.AppSlider} tabIndex="0">
      <div className={styles.AppSlider__Items}>
        {slider?.data?.map((item, index) => (
          <div
            key={index}
            className={classNames(styles.AppSlider__Item, {
              [ styles[ 'AppSlider__Item--Active' ] ]: index === activeIndex,
            })}>
            <AppLottie item={item} />
            <AppLink
              className={classNames(styles.AppSlider__Link, {
                [ styles[ 'AppSlider__Link--Active' ] ]: index === activeIndex,
              })}
              href={`${slider?.parent_slug}/${item.slug}`}
              title={item.title}
              ariaLabel={item.title}>
              {item.title}
            </AppLink>
          </div>
        ))}
      </div>
      <button
        className={classNames(styles.AppSlider__ContorlsPrev, styles.AppSlider__Contorls)}
        onClick={handlePrev}
        title="Previous slide"
        aria-label="Previous slide">
        <Arrow_Left />
      </button>

      <button
        className={classNames(styles.AppSlider__ContorlsNext, styles.AppSlider__Contorls)}
        onClick={handleNext}
        title="Next slide"
        aria-label="Next slide">
        <Arrow_Right />
      </button>
    </div>
  );
}

function Arrow_Left() {
  return (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Previous slide</title>
      <path
        d="M9.5 11.7022V13.1392L0.5 8.00587V5.99413L9.5 0.860798V2.07407V2.29775L2.258 6.3032L2 6.4459V6.74074V7.25926V7.5541L2.258 7.6968L9.5 11.7022Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
}
function Arrow_Right() {
  return (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Next slide</title>
      <path
        d="M0.5 11.7022V13.1392L9.5 8.00587V5.99413L0.5 0.860798V2.07407V2.29775L7.742 6.3032L8 6.4459V6.74074V7.25926V7.5541L7.742 7.6968L0.5 11.7022Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
}
