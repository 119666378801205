import React from 'react';
import dynamic from 'next/dynamic';

import { useAppStateContext } from '../../hooks/context';
import { useTranslation } from '../../hooks/translation';
import AppContainer from '../AppContainer/AppContainer';
import AppButton from '../AppButton/AppButton';
import AppTitle from '../AppTitle/AppTitle';
// import AppText from '../AppText/AppText';
import AppLink from '../AppLink';
import AppSlider from '../AppSlider';
// import AppSliderMobile from '../AppSliderMobile';

import styles from './AppGetInTouch.module.scss';

const AppText = dynamic(() => import('../AppText/AppText'));

export default function AppGetInTouch({ slider }) {

  const { menu, isMobile, parameters } = useAppStateContext().context;

  const { translate } = useTranslation();

  const contact = menu?.data?.filter(
    (item) => item?.position === 'top' && (item?.page_slug === 'contact' || item?.page_slug === 'კონტაქტი'),
  );
  const sliderTitle = parameters?.data?.find((x) => x.keyword === 'home_slider_title')?.value;
  const sliderText = parameters?.data?.find((x) => x.keyword === 'home_slider_text')?.value;

  if (!slider) return null;

  return (
    <section className={styles.AppGetInTouch}>
      <AppContainer>
        <div className={styles.AppGetInTouch__Wrapper}>
          <div className={styles.AppGetInTouch__Content}>
            <div className={styles.AppGetInTouch__Content__Title}>
              <AppTitle>{sliderTitle}</AppTitle>
            </div>
            <div className={styles.AppGetInTouch__Content__Desc}>
              <AppText>{sliderText}</AppText>
            </div>
            <div className={styles.AppGetInTouch__Button}>
              {contact?.map((item, i) => (
                <AppLink
                  key={i}
                  href={item.page_slug}
                  ariaLabel={translate('contact_us')}
                  title={translate('contact_us')}>
                  <AppButton round>{translate('contact_us')}</AppButton>
                </AppLink>
              ))}
            </div>
          </div>
          <div className={styles.AppGetInTouch__Content__Illustration}>
            {isMobile ? (
              <div></div>
            ) : (
              <AppSlider slider={slider} />
            )}
          </div>
        </div>
      </AppContainer>
    </section>
  );
}
