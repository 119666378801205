import React from 'react';
import Lottie from 'react-lottie';

import uxUi from '../../assets/lotties/ux-ui.json';
import graphicDesign from '../../assets/lotties/graphic-design.json';
import mobileDevelopment from '../../assets/lotties/mobile-development.json';
import support from '../../assets/lotties/support.json';
import seo from '../../assets/lotties/seo.json';
import webDevelopment from '../../assets/lotties/web-development.json';

const SERVICES = {
  'ux-ui' : uxUi,
  'graphic-design' : graphicDesign,
  'mobile-development': mobileDevelopment,
  'maintenance-support': support,
  'seo': seo,
  'web-development': webDevelopment,
  'დიზაინი' : uxUi,
  'გრაფიკული-დიზაინი' : graphicDesign,
  'მობილური-აპლიკაციები': mobileDevelopment,
  'ტექნიკური-მომსახურება': support,
  'სეო-ოპტიმიზაცია': seo,
  'ვებ-დეველოპმენტი': webDevelopment,
};

export default function AppLottie({ item }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SERVICES[ item.slug ],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} />;
}
